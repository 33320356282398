<template>
  <b-container fluid lazy>

    <banner-header 
      class="mt-2"
      v-model="searchTerm"
      @setSearchActive="forceSearchActive=!forceSearchActive"
      @searchFocused="forceSearchActive=true"
    />    
    <b-collapse :visible="!!searchTerm || forceSearchActive" appear @hidden="clearParams()">
      <service-search v-model="searchTerm"></service-search>
    </b-collapse>

    <b-collapse :visible="!(!!searchTerm || forceSearchActive)">
      
      <section class="mt-3">
        <h4 class="my-1">{{ $t("integrator.tabs.library.content.highlight_title") }}</h4>
        <highlights-carousel/>
      </section>

      <section class="mt-3"> 
        <h4 class="my-1">Pill Carousel</h4>
        <pill-carousel/>
      </section>

      <section class="mt-2">
        <h4 class="my-1">Description Carousel</h4>
        <description-carousel/>
      </section>

    </b-collapse> 
    
  </b-container>
</template>

<script>
import { 
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BCard,
  BModal,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BPagination,
  BListGroup,
  BListGroupItem,
  BBadge,
  BCollapse
} from 'bootstrap-vue';

import { Carousel, Slide } from 'vue-carousel';
import FilterAdd from '@/views/pages/integrator/components/MyServices/FilterAdd.vue';
import ServiceCategories from '@/views/pages/integrator/components/MyServices/ServiceCategories.vue';
import { mapGetters } from 'vuex';
import avatar from '@/views/pages/integrator/components/General/Avatar.vue';
import BannerHeader from '@/views/pages/integrator/components/NavLibrary/BannerHeader.vue';
import category from '@/store/modules/integrator/category';
import SmallServiceCard from '@/views/pages/integrator/components/ServiceCard/Small.vue';
import ServiceSearch from '@/views/pages/integrator/components/NavLibrary/ServiceSearch.vue';
import ServiceCardDescriptive from '@/views/pages/integrator/components/NavLibrary/ServiceCardDescriptive.vue'
import HighlightsCarousel from '@/views/pages/integrator/components/NavLibrary/HighlightsCarousel.vue'
import PillCarousel from '@/views/pages/integrator/components/NavLibrary/PillCarousel.vue'
import DescriptionCarousel from '@/views/pages/integrator/components/NavLibrary/DescriptionCarousel.vue'

export default {
  components: {
    FilterAdd,
    BannerHeader,
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BButton,
    ServiceCategories,
    BCard,
    avatar,
    BFormSelect,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BListGroup,
    BListGroupItem,
    BBadge,
    SmallServiceCard,
    Carousel,
    Slide,
    BCollapse,
    ServiceSearch,
    ServiceCardDescriptive,
    HighlightsCarousel,
    PillCarousel,
    DescriptionCarousel,
  },
  data() {
      return {
        smallCarousel:{
          idx: 0,
          limit: 3,
        },
        searchTerm: '',
        carouselList: ['i1', 'i2', 'i3', 'i4'],
        forceSearchActive: true 
        
      }
    },
  computed: {
    ...mapGetters('serviceModule', ['getInstalledServicesByCategory', 'getServicesByOrg']),
    organizationID(){
      return localStorage.getItem('selectedOrganization');
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
    },
    shiftCarousel(direction){
      if (direction == 'l'){
        let t  = this.carouselList.shift();
        this.carouselList.push(t)
      }
      if (direction == 'r'){
        let t  = this.carouselList.pop();
        this.carouselList.unshift(t)
      }
    },
    categorySelected(catId){
      console.log(catId)
    },
    shiftSmallCarousel(direction){
      if (direction == 'l'){
        if (this.smallCarousel.idx <= 0){
          this.smallCarousel.idx = this.smallCarousel.limit
        }
        this.smallCarousel.idx = this.smallCarousel.idx-1
      }
      if (direction == 'r'){
        if (this.smallCarousel.idx >= this.smallCarousel.limit-1){
          this.smallCarousel.idx = -1
        }
        this.smallCarousel.idx = this.smallCarousel.idx+1
      }
    },
    clearParams(){
      history.pushState({}, null, this.$route.path);
    },
  },
}
</script>

<style lang="scss" scoped>

.carousel-card{
  width: 48% !important;
  height: fit-content !important;
  display: flex;
  
  position: absolute;
  > * {
    position: relative;
  }
  z-index: 1;
}

.carousel-container{
  position: relative;
  width: 100%;
  height: fit-content;  
  transition: all 1s;
}
.i1{
  left: -50%;
  opacity: 0;
}
.i2{
  left: 0;
  margin: 0 1%;
}
.i3{
  left: 50%;
  margin: 0 1%;
}
.i4{
  left: 100%;
  opacity: 0;
}
.carousel-buttons :hover{
  opacity: 0.8;
}
.carousel-buttons{
  --btn-color: rgba(53, 60, 77, 0.8);
  transition: all 0.3s;
  z-index: 1;
  position: relative;
  height: 200px;
  pointer-events: none;
  
  > * {
    pointer-events: all;
    opacity: 0.3;
    background-color: var(--btn-color) ;
    outline: var(--btn-color);
    border: 1px solid var(--btn-color);
    padding: 18px !important;
    border-radius: 50px;
    z-index: 2;
  }:hover{
    --btn-color: rgba(53, 60, 77, 1);
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.5)!important;
      
    }
    :active{
      --btn-color: rgb(48, 53, 66);
      background-color: var(--btn-color) !important;
      outline: var(--btn-color) !important;
      border: 1px solid var(--btn-color) !important;

    }
  
}

//=======================================================

.small-carousel-nav:hover{
  opacity: 0.8;
}
.small-carousel-nav{
  position: absolute;
  top: 123px; 
  opacity: 0.3;
  z-index: 1;
  display: flex;
  transition: all 0.3s;
  justify-content: space-between;
    > *{
      background-color: rgba(53, 60, 77, 0.8) !important;
    }
    :hover{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  
    .nav-button{
      color: white !important;
      border-radius: 100px;
      padding: 18px;
    
      margin: 0 -30px;
      :active{
        padding: 1px;
      }
      
    }
}
</style>




