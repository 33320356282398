<template>
  <div>

    <div class="d-flex justify-content-center">
      <div>
        <integrator-navbar v-model="navbar"/>
      </div>
    </div>

    
    <!-- LIBRARY ================================ -->
      <template v-if="navbar[0]">
        <library/>
      </template>

    <!-- END OF LIBRARY ========================= -->


    <!-- INSTALLED ============================== -->
      <template v-if="navbar[1]">
        <installed/>
      </template>

    <!-- END OF INSTALLED ======================= -->


    <!-- SERVICES =============================== -->
      <template v-if="navbar[2]"> 
        <favorited/>
      </template>    

    <!-- END OF SERVICES ======================== -->


    <!-- SERVICES =============================== -->
      <template v-if="navbar[3]"> 
        <my-services/>
      </template>    

    <!-- END OF SERVICES ======================== -->
    

  </div>
</template>

<script>
import {
  BRow,
  BCol
} from 'bootstrap-vue'
import MyServices from './components/MyServices/MyServices.vue'
import IntegratorNavbar from '@/views/pages/integrator/components/MyServices/Navbar.vue'
import Installed from '@/views/pages/integrator/components/MyServices/Installed.vue'
import Library from '@/views/pages/integrator/components/NavLibrary/Library.vue'
import Favorited from '@/views/pages/integrator/components/MyServices/Favorited.vue'

  export default {
    components: {
      MyServices,
      IntegratorNavbar,
      Installed,
      Library,
      BRow,
      BCol,
      Favorited,
    },
    data() {
      return {
        navbar: [ true, false, false, false ],
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init(){
        
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>