<template>
  <b-row class="d-block">
    
    <!-- MODAL ALL CATS ========= -->
    <b-modal
      id="all_cats_modal"
      ref="all_cats_modal"
      centered
      body-class="rounded"
      title="Categories: "
      hide-footer
      header-bg-variant="purple"
      body-bg-variant="dark-purple"
    > 
      <div class="d-flex justify-content-center flex-wrap w-100">

        <b-button
          v-for="cat in allCats" :key="'mod-cat-'+cat.id"
          pill
          class="my-50 mx-1 p-1 py-50"
          :variant="selectedCatsId.includes(cat.id) ? 'outline-purple' : 'outline-secondary'"
          @click="selectCat(cat.id)"
        >
          <b>{{cat.label}}</b>
        </b-button>

      </div>

      <template #footer><span/></template>
    </b-modal>
    <!-- ======================== -->
    <template v-if="allCats">
      <b-button class="my-50 mr-50 px-2 py-50" @click="selectCat(0)" pill :variant="selectedCatsId.includes('0') ? 'outline-purple' : 'outline-secondary'">All</b-button>

      <b-button
        v-for="cat in limitedCats" :key="'cat-'+cat.id"
        pill
        class="m-50 px-2 py-50" 
        :variant="selectedCatsId.includes(cat.id) ? 'outline-purple' : 'outline-secondary'"
        @click="selectCat(cat.id)"
        >
          <b>{{cat.label}}</b>
      </b-button>

      <b-button v-if="allCats.length >= 4" class="text-purple" pill variant="none" v-b-modal.all_cats_modal><b>
        View All Categories
      </b></b-button>
    </template>
    
    <div v-else class="m-50 d-flex justify-content-start">
      <b-skeleton width="60px" height="20px" class="mx-1"/>
      <b-skeleton width="60px" height="20px" class="mx-1"/>
      <b-skeleton width="60px" height="20px" class="mx-1"/>
      <b-skeleton width="60px" height="20px" class="mx-1"/>
      <b-skeleton width="60px" height="20px" class="mx-1"/>
      <b-skeleton width="100px" height="20px" class="mx-3"/>
    </div>
  </b-row>
</template>

<script>
import {
  BFormInput,
  BButton,
  BCol,
  BRow,
  BBadge,
  BModal,
  BSkeleton,
  BLink,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BFormInput,
    BButton,
    BCol,
    BRow,
    BBadge,
    BModal, 
    BSkeleton,
    BLink,
  },
  data() {
    return {
      selectedCatsId: ['0'],
      limitedCatList: [],
    }
  },
  props: {
    initicalCatId: {
      type: [Array],
      default: ['0']
    },
  },
  computed: {
    ...mapGetters('categoryModule',['getCategories']),
    allCats(){
      return this.getCategories
    },
    limitedCats(){
      const LIMIT = 4;
      let r = structuredClone(this.allCats)
      
      if (this.limitedCatList && this.limitedCatList.length != 0){
         r = this.limitedCatList;
      }

      if (this.allCats.length > LIMIT){        
        return [r[0],r[1],r[2],r[3],]
      }

      return r;
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      this.fetchAllCAats()
      this.limitedCatList = structuredClone(this.allCats)
      this.selectedCatsId = structuredClone(this.initicalCatId);
    },
    fetchAllCAats(){
      this.$store.dispatch('categoryModule/getAllCategories').then(() =>{
      }).catch((err)=>{
        console.error(err)
      });
    },
    selectCat(catId){
      if (catId == '0'){
        this.selectedCatsId = ['0']
        this.$emit('catSelected', ['0']);
        return
      } else if (this.selectedCatsId.includes('0')){
        let idx = this.selectedCatsId.indexOf('0')
        this.selectedCatsId.splice(idx, 1)
      }

      catId = String(catId) 

      const LIMIT = 4;

      if (this.selectedCatsId.includes(catId)){
        let idx = this.selectedCatsId.indexOf(catId)
        this.selectedCatsId.splice(idx, 1)
      } else {
        this.selectedCatsId.push(catId)
      }

      if (!this.limitedCatList){
         this.limitedCatList = this.allCats
      }
      let selectedIdx = this.limitedCatList.map(e => e.id).indexOf(catId);
      
      let selected = this.limitedCatList[selectedIdx]
      this.limitedCatList.splice(selectedIdx, 1);
      
      this.limitedCatList.unshift(selected)

      if (this.selectedCatsId.length < 1){
        this.selectedCatsId = ['0']
      }

      this.$bvModal.hide(('all_cats_modal'));
      this.$emit('catSelected',this.selectedCatsId);
    }
  },
    
  }
</script>

<style lang="scss" scoped>

</style>