<template>
  <div>
    <carousel
      :touchDrag="false"
      :loop="true"
      :mouseDrag="false"
      :navigationEnabled="true"
      :adjustableHeight="true"
      :perPage="1"
      v-model="smallCarousel.idx"
      class=""
    >
      <template #navigation>
        <div class="small-carousel-nav d-flex w-100">

          <b-button @click="shiftSmallCarousel('l')" class="nav-button" variant="none">
            <b-icon icon="arrow-left-circle" scale="1.4"/>
          </b-button>

          <b-button @click="shiftSmallCarousel('r')" class="nav-button" variant="none">
            <b-icon icon="arrow-right-circle" scale="1.4"/>
          </b-button>

        </div>
      </template>

      <template #pagination>
        <span/>
      </template>
      
      
      <slide>
        <b-row>
          <b-col md="4" v-for="i in 3" class="py-25" :key="`service-card-1-${i}`">
            <service-card-descriptive  :service="banana"/>
          </b-col>
        </b-row>
      </slide>

      <slide>
        <b-row>
          <b-col md="4" v-for="i in 3" class="py-25" :key="`service-card-2-${i}`">
            <service-card-descriptive :service="banana"/>
          </b-col>
        </b-row>
      </slide>

      <slide>
        <b-row>
          <b-col md="4" v-for="i in 3" class="py-25" :key="`service-card-3-${i}`">
            <service-card-descriptive />
          </b-col>
        </b-row>
      </slide>

    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import SmallServiceCard from '@/views/pages/integrator/components/ServiceCard/Small.vue';
import ServiceCardDescriptive from '@/views/pages/integrator/components/NavLibrary/ServiceCardDescriptive.vue'


import { 
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BCard,
  BModal,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BPagination,
  BListGroup,
  BListGroupItem,
  BBadge,
  BCollapse,
} from 'bootstrap-vue';

  export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BListGroup,
    BListGroupItem,
    BBadge,
    SmallServiceCard,
    Carousel,
    Slide,
    BCollapse,
    ServiceCardDescriptive,
  },
  data() {
    return {
      smallCarousel:{
          idx: 0,
          limit: 3,
        },
      banana: { "id": 25, "integrator_category": { "id": 3, "label": "lolz" }, "organization": { "id": 1, "name": "Topdemais" }, "name": "213", "identifier": "213DSA", "host": "S", "short_description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates eos praesentium explicabo voluptatum voluptate consectetur voluptatibus rem id ab! Accusantium aliquid rerum ab iure velit alias culpa dolores odio obcaecati? asgdy tasgdy atdg aystdy gatdfadyt fas9dddddddddddddd6rfatsdgf9astdf a9s7dfasdytas fdasyt dfasd ytasfd", "readme": "ba n\n\nl\n## bah \n\n\naba\n> zaz\n\n\n\n\n| Column 1 | Column 2 | Column 3 |\n| -------- | -------- | -------- |\n| Text | Text | Text |\n\np", "docs_uri": "", "image_uri": "", "cover_uri": "https://random.dog/c5841007-73ab-4794-82e4-48a4763b0aec.jpg", "downloads_counter": 0, "average_rating": 0, "public": false, "created_at": "2022-07-21 19:48:33", "updated_at": "2022-08-09 18:05:53", "is_favorite": false } 

    }
  },
  methods: {
    shiftSmallCarousel(direction){
      if (direction == 'l'){
        if (this.smallCarousel.idx <= 0){
          this.smallCarousel.idx = this.smallCarousel.limit
        }
        this.smallCarousel.idx = this.smallCarousel.idx-1
      }
      if (direction == 'r'){
        if (this.smallCarousel.idx >= this.smallCarousel.limit-1){
          this.smallCarousel.idx = -1
        }
        this.smallCarousel.idx = this.smallCarousel.idx+1
      }
    }
  },
    
  }
</script>

<style lang="scss" scoped>

  .small-carousel-nav:hover{
    opacity: 0.8;
  }
  .small-carousel-nav{
    position: absolute;
    top: 145px; 
    opacity: 0.3;
    z-index: 1;
    display: flex;
    transition: all 0.3s;
    justify-content: space-between;
    pointer-events: none;
      > *{
        pointer-events: all;
        background-color: rgba(53, 60, 77, 0.8) !important;
      }
      :hover{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
    
      .nav-button{
        color: white !important;
        border-radius: 100px;
        padding: 18px;
      
        margin: 0 -30px;
        :active{
          padding: 1px;
        }        
      }
  }
</style>
<style>
.VueCarousel-slide-adjustableHeight{
  height: fit-content !important;
}
</style>