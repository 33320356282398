<template>
  <b-nav pills fill>
    <b-nav-item 
      v-for="(item, index) in navList"
      :key="item.label"
      :active="activeList[index]"
      :to="getHashPath(index)"
      class="integrator-nav-item text-nowrap"
      @click="setActive(index)"
    >
      <feather-icon
        size="16"
        :icon="item.icon"
      />
      <span class="integrator-nav-item-title">
        {{ $t(`integrator.nav.${item.label}`) }}
      </span>
    </b-nav-item>
  </b-nav>
</template>

<script>
import {
  BNav,
  BNavItem
} from 'bootstrap-vue'

  export default {
    components: {
      BNav,
      BNavItem
    },
    props: {
      value: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        
      }
    },
    computed: {
      navList() {
        return [
          { label: 'library', icon: 'BookOpenIcon' },
          { label: 'installed', icon: 'DownloadIcon' },
          { label: 'favorites', icon: 'BookmarkIcon' }, 
          { label: 'my_services', icon: 'ToolIcon' }, 
        ]
      },
      activeList: {
        get() {
          return this.value
        }, 
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    mounted () {
      this.init();
    },
    watch: {
      '$route.params'(newValue, oldValue) {
        this.init()
      }
    },
    methods: {
      init(){
        if(location.hash){
          let r = location.hash;
          r = r.replaceAll('#','');
          let idx = this.navList.findIndex(el => el.label == r);
          if (idx > -1){
            this.setActive(idx)      
          }    
        }
      },
      setActive(index) {
        this.activeList.forEach((_, key) => {
          this.$set(this.activeList, key, key === index ? true : false)
        })
      },
      getHashPath(index){

        return "#"+this.navList[index].label;
      }
    }
  }
</script>

<style lang="scss" scoped>
.integrator-nav-item {
  .integrator-nav-item-title {
    font-size: 16px !important;
  }
}

.active {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
  box-shadow: 0 4px 18px -4px #7367f0 !important;
}

</style>