<template>
  <div>
    <template v-if="true"> <!-- this "if" refers to whether or not the Highlights have been fully loaded -->
    <!-- CAROUSEL ======================================================== -->
    <div class="carousel-container mt-1">
      <b-card :class="'p-50 carousel-card ' + carouselList[i-1]" v-for="i in 4" :key="`carousel-card-${i}`">
          <div class="d-flex align-items-between">
            <avatar
              class="m-0 p-0 d-block"
              :size="143"
              :orgID="organizationID"
              :serviceID="15" 
              serviceGetter="serviceModule/getIntegratorServiceByID"
            /> <!-- !!!!!!!!!!!!!!!!!!! ARRANGE AVATAR PROPS LATER !!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
            <div class="ml-2 w-100">
              <div>
                <b-badge variant="light-purple" pill class="mr-25">Automation</b-badge>
                <b-badge variant="light-warning" pill class="ml-25">
                  <b-icon icon="award" variant="warning" scale="1.2"/> 
                    Service of the Week
                </b-badge>

                <b-button class="float-right m-0 p-0" variant="none">
                  <b-icon icon="bookmark" scale="1.5" />
                </b-button>
              </div>

              <h2 class="mt-50">Service Name</h2>
              <p class="m-0 p-0 text-secondary"><b>Developer ABC</b> </p>
              
              <div class="mt-1">
                <b-button variant="purple">
                  <b-icon icon="download" scale="1" class="mr-1"/>
                  <b>{{ $t("integrator.tabs.library.content.highlight_cards.install") }}</b><!-- b-link to Releases section of ServiceView -->
                </b-button>
              </div>

            </div>
        </div>
      </b-card>
    </div>

    <div class="d-flex justify-content-between align-items-center carousel-buttons">
      
      <b-button variant='purple' class="round p-75 " @click="shiftCarousel('l')">
        <b-icon icon="arrow-left-circle" scale="1.5"/>
      </b-button>

      <b-button variant='purple' class="round p-75 " @click="shiftCarousel('r')">
        <b-icon icon="arrow-right-circle" scale="1.5"/>
      </b-button>
    </div>
    <!-- END OF CAROUSEL ============================================= -->
    </template>
    <div class="mt-1 d-flex justify-content-between px-1" v-else> 
      <b-skeleton height="200px" width="49%" animation="wave"/>
      <b-skeleton height="200px" width="49%" animation="wave"/>
    </div>
  </div>
</template>

<script>
import Avatar from '@/views/pages/integrator/components/General/Avatar.vue';

import { 
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BCard,
  BModal,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BPagination,
  BListGroup,
  BListGroupItem,
  BBadge,
  BCollapse,
  BSkeleton,
} from 'bootstrap-vue';

export default {
  components: { 
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BCard,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BListGroup,
    BListGroupItem,
    BBadge,
    BCollapse,
    Avatar,
    BSkeleton,
  },
    data() {
      return {
        carouselList: ['i1', 'i2', 'i3', 'i4'],
      }
    },
    computed: {
      organizationID(){
        return localStorage.getItem('selectedOrganization');
      },
    },
    methods: {
      shiftCarousel(direction){
      if (direction == 'l'){
        let t  = this.carouselList.shift();
        this.carouselList.push(t)
      }
      if (direction == 'r'){
        let t  = this.carouselList.pop();
        this.carouselList.unshift(t)
      }
    },
    },
  }
</script>

<style lang="scss" scoped>

  .carousel-card{
    width: 48% !important;
    height: fit-content !important;
    display: flex;
    
    position: absolute;
    > * {
      position: relative;
    }
    z-index: 1;
    
  }
  
  .carousel-container{
    position: relative;
    width: 100%;
    height: fit-content;  
    transition: all 1s;
  }
  .i1{
    left: -50%;
    opacity: 0;
  }
  .i2{
    left: 0;
    margin: 0 1%;
  }
  .i3{
    left: 50%;
    margin: 0 1%;
  }
  .i4{
    left: 100%;
    opacity: 0;
  }
  .carousel-buttons :hover{
    opacity: 0.8;
  }
  .carousel-buttons{
    --btn-color: rgba(53, 60, 77, 0.8);
    transition: all 0.3s;
    z-index: 1;
    position: relative;
    height: 200px;
    pointer-events: none;
    
    > * {
      pointer-events: all;
      opacity: 0.3;
      background-color: var(--btn-color) ;
      outline: var(--btn-color);
      border: 1px solid var(--btn-color);
      padding: 18px !important;
      border-radius: 50px;
      z-index: 2;
    }:hover{
      --btn-color: rgba(53, 60, 77, 1);
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.5)!important;
        
      }
      :active{
        --btn-color: rgb(48, 53, 66);
        background-color: var(--btn-color) !important;
        outline: var(--btn-color) !important;
        border: 1px solid var(--btn-color) !important;
  
      }
    
  }
  
</style>