<template>
  <b-container fluid>
      <!-- UNINSTALL MODAL ============ -->
      <b-modal id="uninstallation-modal" centered @hide="((e) =>{ if (modalInfo.loadingUninstall) e.preventDefault()})" size="sm">
        <template #modal-title>
          <span>{{ $t("integrator.tabs.installed.cards.uninstall_modal.title") }}</span>
        </template>

          <p class="mb-0">
            {{ $t("integrator.tabs.installed.cards.uninstall_modal.content")[0] }} <b>{{modalInfo.serviceName}}</b>,<br>
            {{ $t("integrator.tabs.installed.cards.uninstall_modal.content")[1] }} <b>{{modalInfo.releaseName}}</b>?
          </p>

        <template #modal-footer>
          <div align="right">
            <b-button variant="secondary" class="mr-1" @click="$bvModal.hide('uninstallation-modal')" :disabled="modalInfo.loadingUninstall">{{ $t("integrator.tabs.installed.cards.uninstall_modal.cancel") }}</b-button>
            <b-button variant="purple" class="" @click="uninstallRelease()">
              <span v-if="!modalInfo.loadingUninstall">{{ $t("integrator.tabs.installed.cards.uninstall_modal.confirm")}}</span>
              <b-spinner v-else small></b-spinner>
            </b-button>
          </div>
        </template>
      </b-modal>
      <!-- ============================ -->

      <b-row class="my-1">
        <filter-add
          :total="getNumOfServices"
          @search="filter"
        >
          <template #add-button>
            <span/>
          </template>
        </filter-add>
      </b-row>

    <div class="d-flex justify-content-between">
      <service-categories @catSelected="categorySelected(...arguments)" class="mx-50"></service-categories>

      <div class="float-right d-flex justify-content-end align-items-center w-25">
        <span class="w-25 text-right m-1 text-right">{{$t("integrator.tabs.general.order_by")}}</span>
        <b-form-select v-model="sortingBy" :options="sortOptions" class="w-75" no-caret></b-form-select>
        <b-button class="square px-50 my-0 py-50 d-block rounded custom-sorter" variant="none" @click="sortInvert = !sortInvert">
          <b-icon v-if="!sortInvert" icon="chevron-double-up" class="d-inline" variant="muted"></b-icon>
          <b-icon v-else icon="chevron-double-down" variant="muted"></b-icon>
        </b-button>
      </div>

    </div>

    <template v-if="services==-1">
      <div v-for="n in 5">
        <b-overlay show opacity="0">
          <b-skeleton height="110px" width="100%" class="mt-1 rounded" animation="wave throb" :key="'service-skeleton-'+n"/>
        </b-overlay>
      </div>
    </template>

    <template v-if="services && services.length && services!=-1">
        <transition-group type="transition" tag="ul" name="list" class="m-0 p-0 hide-li" id="service-list" ref="service-list">
          <li v-for="service in servicesPerPage" :key="'service'+service.id+service.release.id" class="m-0">
            <b-card class="my-75 p-0" > 
            <b-row>
              <b-col md="4" class="align-items-center d-flex justify-content-start">
                <div class="d-flex">
                  <div class="mr-75">  
                    <avatar
                      :size="75"
                      :orgID="organizationID"
                      :serviceID="service.id"
                      serviceGetter="serviceModule/getIntegratorServiceByID"
                    />         
                  </div>
                  <div class="ml-1 d-flex align-items-center">
                    <div>
                      <h3>{{service.name}}</h3>
                      <p class="mb-50">{{ $t("integrator.tabs.installed.cards.by")}} 
                        <router-link :to="`/integrator/organization/${service.organization.id}/services`" class="text-secondary">
                          <b>{{service.organization.name}}</b>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </b-col>

              <b-col md="2" class="align-items-center d-flex justify-content-start">
                <div>
                  <p class="m-0 p-0 mb-50  text-secondary" ><b>{{ $t("integrator.tabs.installed.cards.category")}} </b></p>
                  <span><b-badge variant="light-purple" pill>{{service.integrator_category.label}}</b-badge></span>
                </div>
              </b-col>
              
              <b-col md="3" class=" align-items-center d-flex justify-content-start">
              <div>
                  <p class="m-0 p-0 mb-50  text-secondary" ><b>{{ $t("integrator.tabs.installed.cards.installed_release")}} </b></p>
                  <span>
                    {{(service.release.label)}}
                    <b-badge
                    pill
                      :variant="getTagVariant(service.release.enum_release_tag.id)"
                      class="mr-0"
                    >
                      {{ $t(service.release.enum_release_tag.label)}}
                    </b-badge>
                    
                  </span>
                </div>
              </b-col>

              <b-col md="3" class="text-center align-items-center d-flex justify-content-end">
                <div align="right" class="align-items-center">
                
                  <router-link :to="`./service/${service.identifier}/view`">
                    <b-button variant="outline-secondary" class="px-3">{{ $t("integrator.tabs.installed.cards.open")}} </b-button>
                  </router-link>

                  <b-dropdown
                    id="dropdown-1"
                    text=""
                    variant="none"
                    icon
                    no-caret
                    dropdown
                    right  
                  > 
                  <template #button-content>
                    <b-icon
                      icon="three-dots"
                      scale="1.4"
                    />
                  </template>

                    <b-dropdown-item @click.native="$router.push(`./service/${service.identifier}/view#review-form`)">
                      <router-link :to="`./service/${service.identifier}/view#review-form`">
                        <b-icon
                        icon="star"
                        scale="1.2"
                        class="mr-1"
                        variant="secondary"
                        />
                        <span class="text-secondary">{{ $t("integrator.tabs.installed.cards.dropdown.rate")}} </span>
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item @click="copyToClipboard(service.name, service.identifier)">
                      <b-icon
                      icon="box-arrow-up"
                      scale="1.2"
                      class="mr-1"
                      variant="secondary"
                      />
                      <span class="text-secondary">{{ $t("integrator.tabs.installed.cards.dropdown.share")}}</span>
                    </b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item @click="openUninstallModal(service.name, service.release.label, service.release.id)">
                      <b-icon
                      icon="trash"
                      scale="1.2"
                      class="mr-1"
                      variant="danger"
                      />
                      <span class="text-danger">{{ $t("integrator.tabs.installed.cards.dropdown.uninstall")}}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </li>
      </transition-group>

      <b-pagination 
        v-model="currentPage"  
        :total-rows="services.length"
        :per-page="limitPerPage"
        first-number
        last-number
        page-class="bg-variant-dark-blue"
        class="mx-1 customPagination pagination-purple"
        align="right"
        :limit="3"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
            class="text-purple"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
            class="text-purple"
          />
        </template>
      </b-pagination>

    </template>

    <template v-else>
      <p v-if="services!=-1 && selectedCatId!=0" class="text-center mt-5 text-secondary"><small><b>{{ $t("integrator.tabs.installed.none_in_cat")}}</b></small></p>
      <p v-if="services!=-1 && selectedCatId==0" class="text-center mt-5 text-secondary"><small><b>{{ $t("integrator.tabs.installed.not_found")}}</b></small></p>
    </template>
    
  </b-container>
</template>

<script>
import { 
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BCard,
  BModal,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BPagination,
  BListGroup,
  BListGroupItem,
  BBadge,
  BSkeleton,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue';
import FilterAdd from './FilterAdd.vue';
import ServiceCategories from '@/views/pages/integrator/components/MyServices/ServiceCategories.vue';
import { mapGetters } from 'vuex';
import avatar from '@/views/pages/integrator/components/General/Avatar.vue'
import ReleaseTags from "@/custom/class/Release/Tags";
import { makeToast } from '@/layouts/components/Popups';



export default {
  components: {
    FilterAdd,
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BButton,
    ServiceCategories,
    BCard,
    avatar,
    BFormSelect,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSkeleton,
    BOverlay,
    BSpinner,
  },
  data() {
      return {
        selectedCatId: null,
        sortOptions:[
          ,{value: 'name', text: 'Name'}
          ,{value: 'date', text: 'Last Installed'}
          ,{value: 'ident', text: 'Identifier'}
          ,{value: 'dev', text: 'Developer'}
        ],
        sortingBy: 'date',
        sortInvert: true,
        currentPage: 1,
        limitPerPage: 10,
        searchTerm: '',
        modalInfo:{
          serviceName: null,
          releaseName: null,
          releaseID: null,
          loadingUninstall: false,
        }
      }
    },
  computed: {
    ...mapGetters('serviceModule', ['getInstalledServices', 'getServicesByOrg']),
    organizationID(){
      return localStorage.getItem('selectedOrganization')
    },
    allServices(){
      if (JSON.stringify(this.getInstalledServices) == "{}"){
        return -1
      }
      return this.getInstalledServices
      
    },
    services(){ 
      let r;

      r = this.allServices

      if (r == -1){
        return -1
      }

      if (!r.length) {
        return 
      }

      if (this.selectedCatId != 0){
        r = r.filter((s) =>{ //category filter
          return s.integrator_category.id == this.selectedCatId
        })
      }

      if (!r.length) {
        return 
      }

      if (this.searchTerm && this.searchTerm.length){
        let search = this.searchTerm; 
        r = r.filter((el) => { //search filter
          return (
            el.name.toLowerCase().indexOf(search.toLowerCase()) > -1 
            || 
            el.updated_at.toLowerCase().indexOf(search.toLowerCase()) > -1
            || 
            el.identifier.toLowerCase().indexOf(search.toLowerCase()) > -1
            ||
            el.organization.name.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        })
      }
      
      switch(this.sortingBy){ //sorting
        case 'name':
          r.sort((a, b) => ((a.name).toLowerCase() > (b.name).toLowerCase()) ? 1 : -1);
          break;
        case 'date':
          r.sort((a, b) => ((a.updated_at).toLowerCase() > (b.updated_at).toLowerCase()) ? 1 : -1);
          break;
        case 'indent':
          r.sort((a, b) => ((a.identifier).toLowerCase() > (b.identifier).toLowerCase()) ? 1 : -1);
          break;
        case 'dev':
          r.sort((a, b) => ((a.organization.name).toLowerCase() > (b.organization.name).toLowerCase()) ? 1 : -1);
          break;
        default:
          r.sort((a, b) => ((a.name).toLowerCase() > (b.name).toLowerCase()) ? 1 : -1);
      }
      
      if (this.sortInvert){
        r.reverse()
      }
      return r
    },
    servicesPerPage(){
      if (this.services.length <= this.limitPerPage){
        return this.services
      }
      let r = structuredClone(this.services)
      return r.slice((this.currentPage-1)*this.limitPerPage,this.currentPage*this.limitPerPage);
    },
    getNumOfServices(){
      let r = this.services;
      if (!r || !Array.isArray(r)) return 0;

      return r.length;
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){

      this.getAllInstalledServices();
      this.categorySelected('0');
    },
    categorySelected(catId) { //if the ID is 0 no category is selected and all services should be shown      
      this.selectedCatId = catId;
    },
    getAllInstalledServices() {
      this.$store.dispatch('serviceModule/fetchInstalledServices', {organizationID: this.organizationID})
        .then(() => {
        }).catch((error) => {
          console.error(error)
        })
    },
    filter(searchTerm){
      this.searchTerm = searchTerm;

    },
    getTagVariant(id){
      return new ReleaseTags(id).variant
    },
    openUninstallModal(serviceName, releaseName, releaseID){
      this.modalInfo.serviceName = serviceName
      this.modalInfo.releaseName = releaseName
      this.modalInfo.releaseID = releaseID
      this.$bvModal.show('uninstallation-modal')
    },
    uninstallRelease(){
      this.modalInfo.loadingUninstall = true;
      this.$store.dispatch('serviceModule/uninstallService', {organizationID: this.organizationID, releaseID: this.modalInfo.releaseID})
        .then(() => {
          this.modalInfo.loadingUninstall = false;
          this.$bvModal.hide('uninstallation-modal')

          makeToast({
            title: this.$t("integrator.tabs.installed.toasts.uninstalled_release.title"),
            text: this.$t("integrator.tabs.installed.toasts.uninstalled_release.text", {"service-name":this.modalInfo.serviceName , "release-name": this.modalInfo.releaseName}),
            variant: 'primary',
            icon: 'CheckIcon'
          })

        }).catch((error) => {
          console.error(error)
        })
    }, 
    copyToClipboard(name, identifier){
      var url = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split('/')[1];
      url = `${url}/service/${identifier}/view`

      navigator.clipboard.writeText(url);

      makeToast({
        title: this.$t("integrator.tabs.installed.toasts.link_copy.title"),
        text: this.$t("integrator.tabs.installed.toasts.link_copy.text", { "service-name": name}),
        variant: 'primary',
        icon: 'CopyIcon'
      })

    }
  },

}
</script>

<style lang="scss" scoped>

  .hide-li{
    list-style: none;
  }

  .custom-sorter{
    width: 35px;
    margin-left: -35px;
    background-color: #283046 !important;
    color: white;
    transition: all 0.3s;    
  }
  .custom-sorter:hover{
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.3) !important;
  }
</style>