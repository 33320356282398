<template>
  <div>
    <router-link :to="`/integrator/service/${service.identifier}/view`" v-if="service">
      <b-card class="card-body p-0 mx-1">
        <div class="header-bg" :style="`--cover-img: url(${service.cover_uri});`">
          <b-icon :icon="service.is_favorite ? 'bookmark' : 'bookmark-fill'" scale="1.8" variant="purple" class="float-right m-1"/>
        </div>   
          <div class="card-content">

            <div>
              <div class="d-flex justify-content-between">
              <div class="avatar-container p-75 mt-4 rounded">
                <b-avatar size="80" class="bg-purple" :src="service.image_uri"/>
              </div>
                <div class="align-self-end"><b-badge variant="light-purple" pill>{{service.integrator_category.label}}</b-badge></div>
              </div>

              <div class="mt-1 px-75 mx-0"> 
                <h4>{{service.name}}</h4>
                <p class="card-description text-secondary text-justify">{{limitedDescription()}}</p>
              </div>
            </div>
           </div>
        <!-- {{service}} -->
      </b-card>
    </router-link>

    <div v-else>
      <b-card class="card-body p-0 mx-1">
        <div class="header-bg">
          <b-skeleton width="25px" height="25px" class="float-right m-1"/>
        </div>   
          <div class="card-content">
            <div>
              <div class="d-flex justify-content-between">
              <div class="avatar-container p-75 mt-4 bg-dark-blue rounded">
                <!-- <b-avatar size="80" class="bg-purple"/> -->
                <b-skeleton type="avatar" height="80px" width="80px" animation="fade"/>
              </div>
                <div class="align-self-end">
                  <b-skeleton width="50px" height="15px" class="mb-25"/>
                </div>
              </div>

              <div class="mt-1 px-0 mx-0"> 
                <b-skeleton height="20px" width="180px"/>
                <p class="card-description text-secondary d-flex flex-wrap mt-2 justify-content-between">
                  <b-skeleton height="15px" :width="randomLength()" class="mr-1 my-25" v-for="i in 15" animation="fade" :key="'bskeleton-word-'+i"/> 
                </p>
              </div>
            </div>
           </div>
      </b-card>
    </div>

  </div>
</template>

<script>
import { 
  BRow,
  BCol,
  BButton,
  BCard,
  BBadge,
  BAvatar,
  BSkeletonIcon,
  BIconstack,
  BSkeleton,
} from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      BCol,
      BButton,
      BCard,
      BBadge,
      BAvatar,
      BSkeletonIcon,
      BIconstack,
      BSkeleton,  
    },
    data() {
      return {
        
      }
    },
    props: {
      service: {
        type: [Object],
        default: null,
      },
    },
    computed: {
      vModel: {
        get() {
          return this.service;
        },
        set(service) {
          this.$emit('input', service);
        }
      },
    },
    methods: {
      limitedDescription() {
        if (!this.service || !this.service.short_description) return
        
        if (this.service.short_description.length > 200){
          return (this.service.short_description.substring(0, 200) + '...');
        }
        return this.service.short_description
      },
    randomLength(){
      let r = Math.floor(Math.random() * 80) + 20
      return r+'px';
    }
    },
  }
</script>

<style lang="scss" scoped>
.card-body{
  overflow: hidden;
  height: 350px;
  transition: all 0.3s;
  cursor: pointer;
}
.card-body:hover{
  transform: scale(1.01);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}
.card-body:active{
  transition: none;
  outline: 2px solid var(--purple);
}

.header-bg{
  background-image: var(--cover-img);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #1f2536;
  height: 145px;
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  box-shadow: inset 0px 10px 80px var(--dark-blue);
}

.card-content{
  position: relative;
}

.avatar-container{
  width: fit-content;
  border: 2px solid var(--dark-blue);
  background-color: var(--dark-blue);
}

.card-description{
  max-height: 110px;
  overflow-y: auto;
}

</style>