<template>
  <div class="w-100 d-flex align-items-center">
    <b-col cols="3" class="d-inline">
      <small class="integrator-muted-text text-muted">
        {{$t("integrator.tabs.general.visualizing", {"total": total} )}}
      </small>
    </b-col>
    <b-col cols="6" class="d-inline d-flex justify-content-center">
      
      <b-form-input
        style="max-width: 570px"
        placeholder="Search services"
        @input="(search) => { $emit('search', search) }"
      />
    </b-col>
    <b-col cols="3" class="d-inline">
      <slot name="add-button">
        <b-button
          class="float-right"
          variant="success"
          :to="{ name: 'integrator-service-create' }"
        >
          <feather-icon
            icon="PlusIcon"
          />
          {{$t("integrator.tabs.general.create_service")}}
        </b-button>
      </slot>
    </b-col>
  </div>
</template>

<script>
import {
  BFormInput,
  BButton,
  BCol
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BButton,
    BCol
  },  
  props: {
    total: {
      type: Number,
      default: 0, 
    },
  },
}
</script>

<style lang="scss" scoped>
.integrator-muted-text {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 24px !important;
}
</style>