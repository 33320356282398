<template>
  <b-container fluid >
      <b-row class="my-1">
        <filter-add
          :total="getNumOfServices"
          @search="filter"
        />
      </b-row>
    <b-row class="mt-3" v-if="Array.isArray(filteredServices)">
      <b-col v-for="(card, index) in filteredServices" :key="card.identifier" cols="4" class="px-2" >
        <small-service-card 
          v-model="filteredServices[index]"
          redirectsTo="edit"
        />
      </b-col>
    </b-row>

    <b-row v-else class="mt-3">
      <b-col cols="4" class="px-2" v-for="i in (6 + Math.floor(Math.random()*3))" :key="'skeleton-service-'+i">
        <small-skeleton/>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { 
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import IntegratorNavbar from './Navbar.vue'
import SmallServiceCard from '.././ServiceCard/Small.vue'
import SmallSkeleton from '@/views/pages/integrator/components/ServiceCard/Small_Skeleton.vue'
import FilterAdd from './FilterAdd'
import { mapGetters } from 'vuex'

  export default {
    components: {
      IntegratorNavbar,
      SmallServiceCard,
      BContainer,
      BRow,
      BCol,
      BFormInput,
      BButton,
      FilterAdd,
      SmallSkeleton,
      BOverlay,
    },
    data() {
      return {
        // navbar: [ false, false, true ],
        filterTerm: ""
      }
    },
    mounted () {
      this.init();
    },
    computed: {
      ...mapGetters('serviceModule', ['getServicesByOrg']),
      services() {
        return this.getServicesByOrg(this.organizationID)
      },  
      organizationID() {
        return localStorage.getItem('selectedOrganization')
      },
      filteredServices(){
        if (!Array.isArray(this.services)) return;
        
        let search = this.filterTerm;
        let r = this.services.filter((el) => {
          return (
            el.identifier.toLowerCase().indexOf(search.toLowerCase()) > -1 
            || 
            el.name.toLowerCase().indexOf(search.toLowerCase()) > -1
            || 
            el.short_description.toLowerCase().indexOf(search.toLowerCase()) > -1
            ||
            el.integrator_category.label.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        })
        return r
      },
      getNumOfServices(){
        let r = this.filteredServices;
        if (!r || !Array.isArray(r)) return 0;

        return r.length;
      }
    },
    methods: {
      init() {
        this.getServicesByOrganization()
      },
      getServicesByOrganization() {
        this.$store.dispatch('serviceModule/fetchIntegratorServicesByOrganization', {organizationID: this.organizationID, currentOrgID: this.organizationID})
          .then(() => {
          }).catch((error) => {
            console.log(error)
          })
      },
      filter(search) {
        this.filterTerm = search
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>