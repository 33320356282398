<template>
  <b-card 
    no-body 
    class="small-card-service cursor-pointer" 
  >
      <b-row class="px-2 pt-2 pb-1">
        <b-col cols="3" class="small-card-service-avatar px-0 mx-1">
          <b-avatar size="95px" class="" rounded src="">
            <template>
              <b-skeleton animation="fade" class="position-absolute  rounded-circle small-card-service-avatar-background mt-1"/>
            </template>
          </b-avatar>
        </b-col>
        <b-col class="ml-0 mr-1 p-0 small-card-service-body">

          <b-skeleton width="20px" height="25px" animation="fade" class="float-right"/>
          
          <span class="text-light mb-50 d-block small-card-service-title">
            <b-skeleton width="150px" height="1em" class="m-0 p-0" animation="fade"/>
          </span>

            <span class="text-secondary small-card-service-organization">
              <b-skeleton width="80px" height="1em" animation="fade"/>
            </span>

          <p class="text-secondary mb-0 small-card-service-rate d-flex m-0 p-0">
            <b-skeleton width="100px" height="1em" animation="fade"/>
            <b-skeleton width="20px" height="1em" animation="fade" class="ml-2"/>
          </p>

        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import { 
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BSkeleton,
} from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BCardBody,
      BRow,
      BCol,
      BAvatar,
      BLink,
      BSkeleton
    },
  }
</script>

<style lang="scss" scoped>

.small-card-service {
  background-color: #1f263b !important;
  box-shadow: none !important;
  transition: all 0.3s ease !important;

  cursor: wait;

  &:hover {
    background-color: #2830467c !important;
    transition: all 0.3s ease !important;
  }
  .small-card-service-avatar {
    .small-card-service-avatar-background {
      padding: 35px 35px !important;
    }
  }
  .small-card-service-body {
    .small-card-service-title {
      font-weight: 500 !important;
      font-size: 21px !important;
      line-height: 26px !important;
    }
    .small-card-service-organization {
      margin-top: 10px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      letter-spacing: 0.4px !important;
      color: #B4B7BD !important;
      :hover{
        text-decoration: underline;
      }
    }
    .small-card-service-rate {
      margin-top: 30px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      letter-spacing: 0.4px !important;
      color: #B4B7BD !important;
    }
  }
}
</style>